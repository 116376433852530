import React from 'react';
import LayoutComponent from '@fuww/library/src/Layout';
import DynamicEmailConfirmationAlert
from './Users/DynamicEmailConfirmationAlert';
import DynamicNavigation from './DynamicNavigation';
import DynamicFooter from './DynamicFooter';
import UserProvider from './UserProvider';

const Layout = ({ ...properties }) => (
  <UserProvider>
    <LayoutComponent
      navigation={<DynamicNavigation />}
      footer={<DynamicFooter />}
      sitewideAlert={(<DynamicEmailConfirmationAlert />)}
      {...properties}
    />
  </UserProvider>
);

export default Layout;
