/**
 * Lighthouse uses 2.625 DPI for calculations, the device simulation
 * is set to 3 DPI.
 *
 * For this reason we cannot have an image of DPI 3 in our source set,
 * as the device will pick 3 DPI image and the audit will calculate a
 * max width/height with 2.625 DPI.`
 *
 * source:
 * https://github.com/GoogleChrome/lighthouse/blob/master/lighthouse-core/config/constants.js#L63 - Devices used in Lighthouse
 * https://github.com/GoogleChrome/lighthouse/blob/master/lighthouse-core/audits/image-size-responsive.js#L286
 * https://github.com/GoogleChrome/lighthouse/commit/a6738e0033e7e5ca308b97c1c36f298b7d399402 - 16 Dec 2020
 */

import { metaImageDimensions } from './constants';
import { ImageResize, ImageFormat, ImageGravity } from './graphql/api/graphql';

export const newsListImageConfigurations = [
  {
    width: 42,
    height: 42,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 80, height: 80, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 160, height: 160, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 320, height: 320, resize: ImageResize.FILL_DOWN,
  },
];

export const newsFeedImageConfigurations = [
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 201, height: 134, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 390, height: 260, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 402, height: 268, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 603, height: 402, resize: ImageResize.FILL_DOWN,
  },
];

export const categoryListImageConfigurations = [
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 330, height: 220, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 660, height: 440, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 720, height: 480, resize: ImageResize.FILL_DOWN,
  },
];

export const articleListImageConfigurations = [
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 312, height: 208, resize: ImageResize.FILL_DOWN, // desktop * 1 dpi
  },
  {
    width: 672, height: 448, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 720, height: 480, resize: ImageResize.FILL_DOWN, // mobile * 2 dpi
  },
];

export const articleSearchImageConfigurations = [
  {
    width: 10,
    height: 7,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 102, height: 68, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 204, height: 136, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 306, height: 204, resize: ImageResize.FILL_DOWN,
  },
];

const metaImageConfiguration = {
  ...metaImageDimensions, resize: ImageResize.FIT, format: ImageFormat.JPEG,
};

export const articleImageConfigurations = [
  metaImageConfiguration,
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 360, height: 240, resize: ImageResize.FILL_DOWN, // mobile * 1 dpi
  },
  {
    width: 694, height: 463, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 720, height: 480, resize: ImageResize.FILL_DOWN, // mobile * 2 dpi
  },
  {
    width: 1388, height: 926, resize: ImageResize.FILL_DOWN,
  },
];

export const ampArticleImageConfigurations = [
  metaImageConfiguration,
  {
    width: 42,
    height: 28,
    format: ImageFormat.WEBP,
    quality: 1,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 42,
    height: 28,
    format: ImageFormat.JPEG,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 320,
    height: 213,
    format: ImageFormat.WEBP,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 320,
    height: 213,
    format: ImageFormat.JPEG,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 375,
    height: 250,
    format: ImageFormat.WEBP,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 375,
    height: 250,
    format: ImageFormat.JPEG,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 414,
    height: 276,
    format: ImageFormat.WEBP,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 414,
    height: 276,
    format: ImageFormat.JPEG,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 725,
    height: 483,
    format: ImageFormat.WEBP,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 725,
    height: 483,
    format: ImageFormat.JPEG,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 740,
    height: 493,
    format: ImageFormat.WEBP,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 740,
    height: 493,
    format: ImageFormat.JPEG,
    resize: ImageResize.FILL_DOWN,
  },
];

export const companyProfilesCardImageConfigurations = [
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 288, height: 192, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 318, height: 212, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 576, height: 384, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 636, height: 424, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 960, height: 640, resize: ImageResize.FILL_DOWN,
  },
];

export const directoryListImageConfigurations = [
  { width: 30, quality: 10 },
  { width: 60 },
  { width: 120 },
  { width: 180 },
];

export const directoryCardImageConfigurations = [
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 318, height: 212, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 636, height: 424, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 960, height: 640, resize: ImageResize.FILL_DOWN,
  },
];

export const directoryCardLogoConfigurations = directoryCardImageConfigurations;

export const epbCardLogoConfigurations = [
  {
    width: 21,
    quality: 10,
    resize: ImageResize.FIT,
  },
  { width: 60, resize: ImageResize.FIT },
  { width: 120, resize: ImageResize.FIT },
];

export const wfbAdsLogoImageConfigurations = [
  {
    width: 42,
    height: 15,
    quality: 10,
    resize: ImageResize.FIT,
  },
  { width: 168, height: 60, resize: ImageResize.FIT },
  { width: 336, height: 120, resize: ImageResize.FIT },
];

export const marketplaceLogoImageConfigurations = [
  { width: 42, quality: 10 },
  { width: 100 },
  { width: 200 },
];

export const marketplaceProductListImageConfigurations = [
  {
    width: 42,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 312, // desktop * 1 dpi
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 624, // mobile * 2 dpi
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 1007, // desktop (1920w) * 2 dpi
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 100,
    height: 1,
    resize: ImageResize.CROP,
    gravity: ImageGravity.NO,
  },
];

export const specialsPageImageConfigurationsMobile = [
  {
    width: 42,
    height: 34,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 360, height: 288, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 720, height: 576, resize: ImageResize.FILL_DOWN,
  },
];

export const specialsPageImageConfigurationsDesktop = [
  {
    width: 42,
    height: 8,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 1350, height: 277, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 2560, height: 526, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 5120, height: 1052, resize: ImageResize.FILL_DOWN,
  },
];

export const textWithImageConfigurations = [
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    width: 360, height: 240, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 460, height: 307, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 920, height: 614, resize: ImageResize.FILL_DOWN,
  },
];

export const mobileSpotlightImageConfigurations = [
  { width: 42 }, { width: 312 }, { width: 624 },
];

export const relatedNewsImageConfigurations = [
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 220, height: 147, resize: ImageResize.FILL_DOWN },
  { width: 440, height: 293, resize: ImageResize.FILL_DOWN },
  { width: 720, height: 480, resize: ImageResize.FILL_DOWN },
];

export const articlePageHighlightedJobsImageConfigurations = [
  { width: 42 },
  { width: 311 },
  { width: 460 },
  { width: 567 },
  { width: 656 },
  { width: 744 },
  { width: 920 },
  { width: 1134 },
];

export const frontPageHighlightedJobsImageConfigurations = [
  { width: 42, quality: 10 },
  { width: 170 },
  { width: 340 },
  { width: 510 },
];

export const moreNewsCardImageConfigurations = [
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 312, height: 208, resize: ImageResize.FILL_DOWN },
  { width: 624, height: 416, resize: ImageResize.FILL_DOWN },
  { width: 720, height: 480, resize: ImageResize.FILL_DOWN },
];

export const moreNewsMixedImageConfigurations = [
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 321, height: 214, resize: ImageResize.FILL_DOWN },
  { width: 642, height: 428, resize: ImageResize.FILL_DOWN },
  { width: 720, height: 480, resize: ImageResize.FILL_DOWN },
];

export const userProfileImageConfigurations = [
  { width: 42, quality: 10 },
  { width: 128 },
  { width: 256 },
  { width: 512 },
];

export const profileJobsListImageConfigurations = [
  {
    width: 42,
    height: 8,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 1350, height: 277, resize: ImageResize.FILL_DOWN },
  { width: 2560, height: 526, resize: ImageResize.FILL_DOWN },
  { width: 5120, height: 1052, resize: ImageResize.FILL_DOWN },
  {
    width: 42,
    height: 34,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 360, height: 288, resize: ImageResize.FILL_DOWN },
  { width: 720, height: 576, resize: ImageResize.FILL_DOWN },
  {
    width: 1200,
    height: 630,
    resize: ImageResize.FIT,
    format: ImageFormat.JPEG,
  },
];

export const profileJobsListMobileImageConfigurations = [
  {
    width: 42,
    height: 34,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 360, height: 288, resize: ImageResize.FILL_DOWN },
  { width: 720, height: 576, resize: ImageResize.FILL_DOWN },
];

export const profileJobsListLogoConfigurations = [
  { width: 42, quality: 10 },
  { width: 220 },
  { width: 440 },
  { width: 660 },
];

export const cityJobsListImageConfigurations = [
  {
    width: 42,
    height: 8,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 360, height: 288, resize: ImageResize.FILL_DOWN },
  { width: 720, height: 576, resize: ImageResize.FILL_DOWN },
  { width: 1350, height: 277, resize: ImageResize.FILL_DOWN },
  { width: 2560, height: 526, resize: ImageResize.FILL_DOWN },
  { width: 5120, height: 1052, resize: ImageResize.FILL_DOWN },
];

export const jobDetailImageConfigurations = [
  {},
  {
    width: 1200,
    height: 630,
    resize: ImageResize.FIT,
    format: ImageFormat.JPEG,
  },
];

export const jobDetailLogoImageConfigurations = [
  { width: 42, quality: 10, resize: ImageResize.FILL_DOWN },
  { width: 300, resize: ImageResize.FILL_DOWN },
  { width: 600, resize: ImageResize.FILL_DOWN },
  { width: 900, resize: ImageResize.FILL_DOWN },
  {
    width: 1200,
    height: 630,
    resize: ImageResize.FIT,
    format: ImageFormat.JPEG,
  },
];

export const jobSearchListLogoImageConfigurations = [
  { width: 42, quality: 10 },
  { width: 100 },
  { width: 120 },
  { width: 200 },
];

export const relatedJobsLogoImageConfigurations = [
  { width: 42, quality: 10 },
  { width: 100 },
  { width: 120 },
  { width: 200 },
];

export const lookbookDetailImageConfigurations = [
  { width: 1200, height: 630, resize: ImageResize.FIT },
  { width: 1698, resize: ImageResize.FIT },
  { width: 42, quality: 10, resize: ImageResize.FILL_DOWN },
  { width: 328, resize: ImageResize.FILL_DOWN },
  { width: 477, resize: ImageResize.FILL_DOWN },
  { width: 656, resize: ImageResize.FILL_DOWN },
  { width: 849, resize: ImageResize.FILL_DOWN },
  { width: 954, resize: ImageResize.FILL_DOWN },
  { width: 1698, resize: ImageResize.FILL_DOWN },
];

export const lookbookBrowserImageConfigurations = [
  { width: 42, quality: 10 },
  { width: 360 },
  { width: 540 },
  { width: 720 },
  { width: 1440 },
];

export const coursesListLogoImageConfigurations = [
  { width: 42, quality: 10 },
  {},
  { width: 102 },
  { width: 204 },
  { width: 306 },
];

export const coursesListImageConfigurations = [
  {
    width: 42,
    height: 8,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 1280, height: 263, resize: ImageResize.FILL_DOWN },
  { width: 2560, height: 526, resize: ImageResize.FILL_DOWN },
  { width: 5120, height: 1052, resize: ImageResize.FILL_DOWN },
];

export const coursesListMobileImageConfigurations = [
  {
    width: 42,
    height: 34,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 360, height: 305, resize: ImageResize.FILL_DOWN },
  { width: 720, height: 610, resize: ImageResize.FILL_DOWN },
];

export const companyProfileImageConfigurations = [
  {
    width: 42,
    height: 8,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 1350, height: 277, resize: ImageResize.FILL_DOWN },
  { width: 2560, height: 526, resize: ImageResize.FILL_DOWN },
  { width: 5120, height: 1052, resize: ImageResize.FILL_DOWN },
  {
    width: 42,
    height: 34,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 360, height: 288, resize: ImageResize.FILL_DOWN },
  { width: 720, height: 576, resize: ImageResize.FILL_DOWN },
  {
    width: 1200,
    height: 630,
    resize: ImageResize.FIT,
    format: ImageFormat.JPEG,
  },
];

export const companyProfileMobileImageConfigurations = [
  {
    width: 42,
    height: 34,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  { width: 360, height: 288, resize: ImageResize.FILL_DOWN },
  { width: 720, height: 576, resize: ImageResize.FILL_DOWN },
];

export const companyProfileLogoImageConfigurations = [
  { width: 42, quality: 10 },
  { width: 102 },
  { width: 204 },
  { width: 306 },
];

export const companyProfileCollectionImageConfigurations = [
  { width: 42, quality: 10 },
  { width: 280 },
  { width: 560 },
  { width: 936 },
];

export const relatedProductsImageConfigurations = [
  { width: 42, height: 56, quality: 10 },
  { width: 138, height: 184 },
  { width: 276, height: 368 },
  { width: 552, height: 736 },
];

export const currentUserImageConfigurations = [
  { width: 24, height: 24, quality: 10 },
  { width: 24, height: 24 },
  { width: 48, height: 48 },
  { width: 72, height: 72 },
];

export const featuredNewsImageConfigurations = [
  {
    width: 42,
    height: 28,
    quality: 10,
    resize: ImageResize.FILL_DOWN,
  },
  {
    // Small Card: desktop * 1 dpi
    width: 335, height: 223, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 502, height: 335, resize: ImageResize.FILL_DOWN,
  },
  {
    // Big Card: desktop * 1 dpi
    width: 570, height: 380, resize: ImageResize.FILL_DOWN,
  },
  {
    width: 621, height: 414, resize: ImageResize.FILL_DOWN,
  },
  { width: 670, height: 447, resize: ImageResize.FILL_DOWN },
  {
    // Big/Small Card: mobile * 2 dpi
    width: 720, height: 480, resize: ImageResize.FILL_DOWN,
  },
  { width: 1242, height: 828, resize: ImageResize.FILL_DOWN },
];

export const productDetailImageConfigurations = [
  { width: 42, quality: 10 },
  { width: 702 },
  { width: 850 },
  { width: 1008 },
  { width: 1700 },
];

export const specialsNewsListImageConfigurations = [
  { width: 42, height: 28, quality: 10 },
  { width: 330, height: 220 },
  { width: 660, height: 440 },
];

export const allImageConfigurations = [
  ...newsListImageConfigurations,
  ...newsFeedImageConfigurations,
  ...categoryListImageConfigurations,
  ...articleListImageConfigurations,
  ...articleSearchImageConfigurations,
  ...articleImageConfigurations,
  ...ampArticleImageConfigurations,
  ...directoryListImageConfigurations,
  ...directoryCardImageConfigurations,
  ...epbCardLogoConfigurations,
  ...marketplaceLogoImageConfigurations,
  ...marketplaceProductListImageConfigurations,
  ...specialsPageImageConfigurationsMobile,
  ...specialsPageImageConfigurationsDesktop,
  ...mobileSpotlightImageConfigurations,
  ...relatedNewsImageConfigurations,
  ...articlePageHighlightedJobsImageConfigurations,
  ...frontPageHighlightedJobsImageConfigurations,
  ...moreNewsCardImageConfigurations,
  ...moreNewsMixedImageConfigurations,
  ...userProfileImageConfigurations,
  ...jobDetailImageConfigurations,
  ...jobDetailLogoImageConfigurations,
  ...jobSearchListLogoImageConfigurations,
  ...relatedJobsLogoImageConfigurations,
  ...wfbAdsLogoImageConfigurations,
  ...lookbookDetailImageConfigurations,
  ...lookbookBrowserImageConfigurations,
  ...coursesListLogoImageConfigurations,
  ...coursesListImageConfigurations,
  ...coursesListMobileImageConfigurations,
  ...profileJobsListImageConfigurations,
  ...profileJobsListMobileImageConfigurations,
  ...profileJobsListLogoConfigurations,
  ...cityJobsListImageConfigurations,
  ...companyProfileImageConfigurations,
  ...companyProfileMobileImageConfigurations,
  ...companyProfileLogoImageConfigurations,
  ...companyProfileCollectionImageConfigurations,
  ...relatedProductsImageConfigurations,
  ...currentUserImageConfigurations,
  ...featuredNewsImageConfigurations,
  ...productDetailImageConfigurations,
  ...specialsNewsListImageConfigurations,
];
